<template>
  <div>
    <iframe
      :src='frame'
      width='100%'
      height='1200px'
      frameborder='0'
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontBody = ref(14)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsSendBodySick({
      phrf_id: route.value.query.id.split(','),
      lang: i18n.locale,
    }).then(res => {
      dataTableList.value = res.orderdetail
      savePDF(res)
    })

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      })
      doc.setProperties({
        title: `ใบส่งตัวผู้ป่วย - ${data.length == 1 ? `${data[0].customer_id} ${data[0].customer_fullname}  - ${data[0].opd_date}` : ''}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      for (let i = 0; i < data.length; i++) {
        doc.addImage(require('./SendBodySick.jpg'), 'JPEG', 0, 0, 210, 297)
        doc.text(`${i18n.t('a_number')} ${data[i].phrf_code}`, 4, 20)
        doc.text(data[i].customer_fullname.replaceAll('null', ''), 19, 35.8)
        doc.text(data[i].customer_gender, 161, 35.8)
        doc.text(String(data[i].customer_age), 194, 35.8, null, null, 'center')
        doc.text(`${data[i].customer_address}`, 13, 43.5)
        doc.text(`${data[i].customer_tel}`, 168, 43.5)
        doc.text(`${data[i].shop_name}`, 148, 50.5)
        const idCard = []
        let myCard = data[i].customer_idcard.replaceAll('-', '')
        if (data[i].customer_idcard && myCard.length === 13) {
          for (let a = 0; a < data[i].customer_idcard.length; a++) {
            idCard.push(data[i].customer_idcard[a])
          }
          const idCardHeight = 14
          doc.setFontSize(16)
          doc.text(idCard[0], 118.7, idCardHeight, null, null, 'center')
          doc.text(idCard[1], 126.8, idCardHeight, null, null, 'center')
          doc.text(idCard[2], 132.8, idCardHeight, null, null, 'center')
          doc.text(idCard[3], 138.8, idCardHeight, null, null, 'center')
          doc.text(idCard[4], 144.8, idCardHeight, null, null, 'center')
          doc.text(idCard[5], 152.8, idCardHeight, null, null, 'center')
          doc.text(idCard[6], 158.8, idCardHeight, null, null, 'center')
          doc.text(idCard[7], 164.6, idCardHeight, null, null, 'center')
          doc.text(idCard[8], 170.6, idCardHeight, null, null, 'center')
          doc.text(idCard[9], 176.7, idCardHeight, null, null, 'center')
          doc.text(idCard[10], 184.6, idCardHeight, null, null, 'center')
          doc.text(idCard[11], 190.7, idCardHeight, null, null, 'center')
          doc.text(idCard[12], 198.5, idCardHeight, null, null, 'center')
        }
        doc.setFontSize(fontBody.value)
        const ccs = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].opd_cc || '-', 90)
        if (ccs.length === 2) {
          doc.text(105, 80.3, ccs[0])
          doc.text(85, 87.3, ccs[1])
        } else {
          doc.text(105, 80.3, ccs[0])
        }
        const phi = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].opd_hpi || '-', 90)
        if (phi.length == 2) {
          doc.text(113, 94.3, phi[0])
          doc.text(85, 101.3, phi[1])
        } else {
          doc.text(113, 94.3, phi[0])
        }

        const disease = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].customer_disease || '-', 95)
        if (disease.length == 2) {
          doc.text(106, 108.3, disease[0])
          doc.text(85, 115.3, disease[1])
        } else {
          doc.text(106, 108.3, disease[0])
        }
        doc.text(105, 115.4, data[i].customer_allergic)

        const phi2 = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].opd_pmh || '-', 90)
        if (phi2.length == 2) {
          doc.text(108, 122.3, phi2[0])
          doc.text(85, 129.3, phi2[1])
        } else {
          doc.text(108, 122.3, phi2[0])
        }

        const problems = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].phrf_comment, 98)
        if (problems.length > 1) {
          doc.text(104, 136.3, problems[0])
          let totalArr = ''
          problems.forEach((item, index) => {
            if (index >= 1) {
              totalArr += item
            }
          })
          const problems2 = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(totalArr, 113)
          let startComment = 143.5
          problems2.forEach(element => {
            doc.text(88, startComment, element)
            startComment += 6.9
          })
        } else {
          doc.text(104, 136.3, problems)
        }
        doc.setFontSize(16)
        if (data[i].phrf_basic_id == '1') {
          doc.addImage(require('./check.png'), 'JPEG', 7.6, 188.5, 3.5, 3.5)
          const phrf_basic = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].phrf_basic, 150)
          let space = 192.7
          for (let x = 0; x < phrf_basic.length; x++) {
            let positionStarter = 0
            if (x >= 1) {
              positionStarter = 13
            } else {
              positionStarter = 50
            }
            doc.text(positionStarter, space, phrf_basic[x])
            space += 7
          }
        }
        if (data[i].phrf_suggest_id == '1') {
          doc.addImage(require('./check.png'), 'JPEG', 7.6, 202, 3.5, 3.5)
          const phrf_suggest = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].phrf_suggest, 130)
          let space = 206.2
          for (let x = 0; x < phrf_suggest.length; x++) {
            let positionStarter = 0
            if (x >= 1) {
              positionStarter = 13
            } else {
              positionStarter = 70
            }
            doc.text(positionStarter, space, phrf_suggest[x])
            space += 7
          }
        }
        if (data[i].phrf_note_id == '1') {
          doc.addImage(require('./check.png'), 'JPEG', 7.6, 215.7, 3.5, 3.5)
          const phrf_note = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].phrf_note, 180)
          let space = 219.9
          for (let x = 0; x < phrf_note.length; x++) {
            let positionStarter = 0
            if (x >= 1) {
              positionStarter = 13
            } else {
              positionStarter = 20
            }
            doc.text(positionStarter, space, phrf_note[x])
            space += 7
          }
        }

        // doc.text('√', 7.9, 191.8)

        doc.text(`${data[i].user_fullname}`, 105, 248.7, null, null, 'left')
        doc.text(`${data[i].user_license}`, 123, 256.5, null, null, 'left')
        doc.text(`${data[i].opd_date_d} ${data[i].opd_date_m} ${data[i].opd_date_y}`, 94, 264.5, null, null, 'left')
        doc.text(`${data[i].shop_name}`, 21, 280.5, null, null, 'left')
        doc.text(`${data[i].shop_tel}`, 140, 280.5, null, null, 'left')
        doc.text(`${data[i].shop_address} ${data[i].shop_district} ${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`, 15, 288.5, null, null, 'left')
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
